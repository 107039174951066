import React from 'react'
import { Navigation, Pagination, Thumbs, EffectFade, Autoplay } from "swiper";
import { Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "./activity.css";

export default function Activity() {
    const slides = Array.from({ length: 10 }).map(
        (el, index) => `Slide ${index + 1}`
      );
  return (
    <Swiper
    modules={[Virtual, Navigation, Pagination, Thumbs, EffectFade, Autoplay]}
    spaceBetween={10}
    slidesPerView={3}
    virtual
    autoplay={{ delay: 5000, disableOnInteraction: false }}
    loop={true}
    className="h-96 relative overflow-hidden rounded-3xl "
    navigation
    pagination={{
      dynamicBullets: true,
      clickable: true,
    }}
    breakpoints={{
      320: { slidesPerView: 1 },

      768: { slidesPerView: 3 },
    }}
  >
    {slides.map((slideContent, index) => (
      <SwiperSlide
        key={slideContent}
        virtualIndex={index}
        className=" bg-red-light text-blue-primary rounded-3xl"
      ></SwiperSlide>
    ))}
  </Swiper>
  )
}
