import React from "react";
import logo from "../../assets/images/mobile/event-logo-mobile.png";
export default function Footer() {
  //setting invite
  const invite = true;
  return (
    <div className="flex flex-col font-['FcMinimal-normal'] text-black-text text-center">
      {/* tablet desktop */}
      <section id="desktop">
        <div
          className={`${
            invite ? "pb-28" : ""
          } mobile:hidden tablet:flex bg-dark-accent flex-col justify-center items-center p-8 `}
        >
          <div className="mt-8">
            <img src={logo} alt="" className="scale-90" />
          </div>
          <h4 className="mt-4 text-2xl">
            สอบถามรายละเอียดเพิ่มเติม <br />
            โทร 02-001-5375 Email: Hackathon@techsauce.co
            <br /> วันจันทร์ - ศุกร์ เวลา 9.00 - 18.00 น.
          </h4>
          <ul className="flex flex-row items-center w-full justify-center gap-2 laptop:gap-8 text-red-accent text-base laptop:text-lg mt-8">
            <li className="p-2">
              <a href="#about">เกี่ยวกับโครงการ</a>
            </li>
            <li>|</li>

            <li className="p-2">
              <a href="#challenge">โจทย์จากโครงการ</a>
            </li>
            <li>|</li>

            <li className="p-2">
              <a href="#criteria">คุณสมบัติผู้สมัคร</a>
            </li>
            <li>|</li>
            <li className="p-2">
              <a href="#benefit">สิ่งที่จะได้รับ</a>
            </li>
            <li>|</li>

            <li className="p-2">
              <a href="#application-process">ขั้นตอนการสมัคร</a>
            </li>
            {/* <li>|</li> */}

            {/* <li className="p-2">
            <a href="#activity">ภาพบรรยากาศ</a>
          </li> */}
          </ul>
        </div>
      </section>
      {/* mobile */}
      <section id="mobile">
        <div
          className={`${
            invite ? "pb-28" : ""
          } flex tablet:hidden bg-blue-primary flex-col justify-center items-center p-8`}
        >
          <div className="mt-8">
            <img src={logo} alt="" className="scale-[0.6]" />
          </div>
          <h4 className="-mt-4 text-xl">
            สอบถามรายละเอียดเพิ่มเติม <br />
            โทร 02-001-5375
            <br />
            Email: Hackathon@techsauce.co
            <br /> วันจันทร์ - ศุกร์ เวลา 9.00 - 18.00 น.
          </h4>
          <div className="text-yellow-accent text-xl mt-8 underline text-red-accent underline-offset-2">
            <a href="#hero">กลับขึ้นด้านบน</a>
          </div>
        </div>
      </section>
    </div>
  );
}
