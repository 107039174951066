import React from "react";
interface ChallengeProps {
  image: string;
  title: string;
  detail: string;
}
export default function ChallengeCard(props: ChallengeProps) {
  return (
    <div className="flex flex-col shadow-challenge p-4 ">
      <img
        src={props.image}
        alt={props.title}
        className="min-h-[176px] tablet:h-[220px]"
      />
      <h2 className='text-red-accent font-["FcMinimal-bold"] whitespace-pre-line mt-2 text-xl tablet:text-2xl'>
        {props.title}
      </h2>
      <p className="text-black-text whitespace-pre-line">{props.detail}</p>
    </div>
  );
}
