import React from 'react';
import './App.css';
import Footer from './components/footer/footer';
import Navbar from './components/navbar/navbar';
import HomePage from './page/home-page';

function App() {
  return (
    <div className="App">
    <Navbar/>
     <HomePage/>
     <Footer/>
    </div>
  );
}

export default App;
