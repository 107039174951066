import React from "react";
import techsauce from "../assets/images/partner/techsauce-logo.png";
import etda from "../assets/images/partner/etda-logo.png";
import eventLogoDesktop from "../assets/images/desktop/event-logo-desktop.png";
import eventLogoMobile from "../assets/images/mobile/event-logo-mobile.png";
import bannerMobile from "../assets/images/mobile/banner-mobile.png";
import about from "../assets/images/both/about.png";
import redArrow from "../assets/images/both/arrow.png";
import criteriaMobile from "../assets/images/mobile/criteria-mobile.png";
import trophy from "../assets/images/mobile/trophy-mobile.png";
import processMobile from "../assets/images/mobile/application-process-mobile.png";
import lineBenefit from "../assets/images/desktop/benefit-line-desktop.png";
import lineCriteria from "../assets/images/desktop/criteria-line-desktop.png";
import ChallengeCard from "../components/challenge/challenge-component";
import Activity from "../components/activity/activity";
import challenge1 from "../assets/images/both/Online-Growth.jpg";
import challenge2 from "../assets/images/both/People-Growth.jpg";
import challenge3 from "../assets/images/both/Travel-Business-Growth.jpg";
// import Calendar from "../components/calendar/calendar";
const Challenges = () => {
  const _challenge = [
    {
      image: challenge2,
      title: "People Growth",
      detail:
        "โซลูชันเพื่อคนทำงาน ด้วยการพัฒนาทรัพยากรที่สำคัญที่สุดในกระบวนการ Digital Transformation เพื่อยกระดับชีวิตคนทำงานให้ดียิ่งขึ้น\n\nปัญหาที่พบ:\n ผู้ประกอบการ: ขาดเครื่องมือในการบริหารทรัพยากร เมื่อองค์กรมีการขยายตัว\nพนักงาน: ข้อจำกัดด้านความเชี่ยวชาญ หรือความรู้ของพนักงาน รวมถึงขาดความรู้ในสายงานที่เกี่ยวข้องกับเครื่องมือดิจิทัล",
    },
    {
      image: challenge1,
      title: "Online Business Growth",
      detail: `โซลูชันที่ช่วยให้ผู้ประกอบการโดยเฉพาะธุรกิจการค้าดิจิทัล เช่น พ่อค้าแม่ค้าออนไลน์ \nE-Marketplace สามารถใช้ประโยชน์จากข้อมูลที่มีได้อย่างมีประสิทธิภาพ อีกทั้งยังช่วยให้การบริหารจัดการและการให้บริการลูกค้าได้ดีขึ้น ในค่าบริการโซลูชันที่เหมาะสมกับขนาดธุรกิจ\n\nปัญหาที่พบ:\nข้อมูลยอดขายและข้อมูลลูกค้ายังไม่เชื่อมโยงระหว่างแหล่งที่มาต่างๆ ภายในองค์กร หรือระหว่างเครื่องมือต่างๆ ตลอดจนผู้ประกอบการรายเล็กพบว่าเครื่องมือในตลาดที่มี มีราคาสูง ส่งผลต่อต้นทุนและความคุ้มค่าในการลงทุน`,
    },

    {
      image: challenge3,
      title: "Travel Business Growth",
      detail:
        "โซลูชันที่ช่วยให้ผู้ประกอบการโดยเฉพาะธุรกิจการท่องเที่ยว ซึ่งถือเป็นธุรกิจที่ได้กลับมาฟื้นตัวอีกครั้ง จำเป็นต้องอาศัยความรวดเร็วของข้อมูล เพื่อประกอบการวางแผนได้เหมาะสมกับและสามารถตอบโจทย์ความต้องการของธุรกิจท่องเที่ยวได้\n\nปัญหาที่พบ:\nการบริหารธุรกิจมีอุปสรรค ทั้งในการนำข้อมูลที่เชื่อมโยงกับความต้องการของลูกค้ามาใช้เพื่อวางแผนภายในองค์กร รวมไปถึงการวางแผนให้ตอบสนองต่อความต้องการของลูกค้าได้รวดเร็ว ",
    },
  ];
  return (
    <div className="grid grid-cols-1 tablet:grid-cols-3 justify-center items-stretch gap-4  p-4 tablet:p-20  tablet:pt-4">
      {_challenge.map((item, index) => (
        <div key={item.title + index} className={`h-full flex flex-col`}>
          <h3
            className={`text-red-accent font-['FcMinimal-bold'] text-3xl mb-4 ${
              index === 2 ? "invisible" : ""
            }`}
          >
            {index === 0 ? item.title : index === 1 ? "Business Growth" : "-"}
          </h3>
          <div className="flex h-full items-center ">
            <ChallengeCard
              image={item.image}
              title={item.title}
              detail={item.detail}
            />
            {index === 0 && (
              <div className="border-r-2 border-black-text opacity-30 ml-8 mr-4 h-[70%] "></div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
const Winner = () => {
  const team: string[] = [
    "ทีม One-million",
    "ทีม One-million",
    "ทีม One-million",
    "ทีม One-million",
    "ทีม One-million",
  ];
  return (
    <>
      {team.map((name: string, index: number) => {
        return (
          <div
            key={name + index}
            className={`${
              team.length - 1 === index && team.length % 2 === 1
                ? "tablet:col-span-2 tablet:items-center tablet:mx-auto tablet:w-1/2"
                : ""
            } flex flex-row justify-start items-center px-4 py-3 text-red-accent rounded-[15px] bg-gray-background mobile:text-2xl tablet:text-3xl shadow-announcement`}
          >
            {name}
          </div>
        );
      })}
    </>
  );
};

const Calendar = () => {
  const data = [
    {
      month: "7 มี.ค.- 21 เม.ษ. 66",
      title: "ประชาสัมพันธ์และเปิดรับสมัครทีมเข้าร่วมโครงการ",
      detail:
        "ประชาสัมพันธ์ผ่านช่องทางต่างๆ ของ Techsauce และจอประชาสัมพันธ์ในบริเวณ ของ True Digital Park",
    },
    {
      month: "28 เม.ษ. 66",
      title: "ประกาศรายชื่อ 40 ทีมที่ได้รับคัดเลือกเข้าร่วมโครงการ",
      detail: "ประกาศรายชื่อ 40 ทีม\nผ่าน Facebook ETDA",
    },
    {
      month: "9 พ.ค. 66",
      title: "กิจกรรม Open House",
      detail:
        "กิจกรรมต้อนรับ 40 ทีม ที่ได้รับคัดเลือกเข้าร่วมโครงการ และชี้แจงรายละเอียดของโครงการพร้อมทั้ง session สุดพิเศษที่ Service provider รุ่นพี่จะมาแบ่งปันประสบการณ์ ให้กับทีมผู้เข้าร่วม",
    },
    {
      month: "18 และ 24 พ.ค. 66",
      title: "กิจกรรม Workshop",
      detail:
        "กิจกรรม Workshop ในรูปแบบ Onsite เพื่อให้ทีมผู้เข้าร่วม ได้เรียนรู้ จากการลงมือทำ และนำความรู้ไปพัฒนา Solution หรือธุรกิจให้ดียิ่งขึ้น",
    },
    {
      month: "19 และ 25 พ.ค. 66",
      title: "กิจกรรม Mentoring",
      detail:
        "ทีมผู้เข้าร่วมจะได้พูดคุยกับ Mentor\n ผู้เชี่ยวชาญ ในรูปแบบออนไลน์แบบ 1:1\n เพื่อรับคำแนะนำไปปรับใช้ กับธุรกิจ",
    },
    {
      month: "30 พ.ค. 66",
      title: "กิจกรรม Online Pitching เพื่อคัดเลือก 20 ทีม",
      detail:
        "ทีมผู้เข้าร่วมทั้งหมด 40 ทีม ทำการ Pitching เพื่อคัดเลือก 20 ทีมเข้าสู่รอบชิงชนะเลิศ",
    },
    {
      month: "2 มิ.ย. 66",
      title: "ประกาศรายชื่อ 20 ทีมที่ได้เข้าสู่รอบชิงชนะเลิศ",
      detail: "ประกาศรายชื่อ 20 ทีม\nผ่าน Facebook ETDA",
    },
    {
      month: "28 มิ.ย. 66",
      title: "งานแข่งขันรอบชิงชนะเลิศ",
      detail:
        "กิจกรรมจะประกอบไปด้วยกิจกรรม Pitching, Business Matching และ Networking",
    },
  ];
  return (
    <div className="mobile:flex mobile:flex-col mobile:items-center tablet:grid tablet:grid-cols-3 laptop:grid-cols-4 gap-4  p-4 tablet:p-20 tablet:pt-4 bg-vector-calendar">
      {data.map((item: any, index: number) => (
        <div
          key={item.title}
          className={`${
            index === 4
              ? "laptop:order-8"
              : index === 5
              ? "laptop:order-7"
              : index === 6
              ? "laptop:order-6"
              : index === 7
              ? "laptop:order-5"
              : ""
          } flex flex-col max-w-[18rem] items-center p-4 h-[280px] tablet:h-[300px] laptop:min-w-[246px]`}
        >
          <div className='text-red-accent border flex justify-center items-center bg-white-primary border-red-accent font-["FcMinimal-bold"] w-10 h-10 rounded-[50%] text-4xl'>
            {index + 1}
          </div>
          <div className="bg-red-accent text-white-primary rotate-[-2.56deg] flex justify-center mt-4 px-4 min-w-[11rem]">
            <h2 className='rotate-[2.56deg] font-["FcMinimal-bold"] tablet:text-xl laptop:text-2xl'>
              {item.month}
            </h2>
          </div>
          <div className="flex flex-col items-center mt-4 text-center">
            <h3 className='text-red-accent font-["FcMinimal-bold"] text-xl laptop:text-2xl'>
              {item.title}
            </h3>
            <p className="text-black-text text-lg whitespace-pre-line">
              {item.detail}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default function HomePage() {
  const invite: boolean = true; // toggle wording on banner
  const openButton: boolean = false; //toggle invite button
  const openSection = {
    banner: true,
    about: true,
    winner: false,
    activity: false,
    challenge: true,
    criteria: true,
    applicationProcess: true,
    benefit: true,
    calendar: true,
    partners: true,
  }; // toggle open/close section

  return (
    <>
      {/* banner */}
      {openSection.banner && (
        <section id="banner" className="bg-[#f6f4f2]">
          <div className=' text-red-accent tablet:p-8 tablet:mt-0  tablet:h-[90vh] bg-banner font-["FcMinimal-normal"] flex flex-col mobile:items-center tablet:justify-center tablet:items-end  '>
            {/* mobile */}
            <div className="tablet:hidden mt-16 w-full ">
              <div className="flex justify-center px-4">
                <img src={eventLogoMobile} alt="" />
              </div>

              {invite ? (
                <h1 className='font-["FcMinimal-bold"] text-3xl text-black-text text-center mt-4 px-4 relative z-10'>
                  เปิดรับสมััคร
                  <br />
                  วันที่ 7 มีนาคม - 21 เมษายน 2566
                </h1>
              ) : (
                <h1 className='font-["FcMinimal-bold"] text-3xl text-black-text text-center mt-4 px-4 relative z-10'>
                  ปิดรับสมัคร
                </h1>
              )}
              <div className=" -mt-16 h-[50vh] flex justify-center ">
                <img
                  src={bannerMobile}
                  alt=""
                  className="w-full min-[490px]:w-auto "
                />
              </div>
            </div>
            {/* desktop */}
            <div className="hidden tablet:block">
              <div className="flex justify-end">
                <img src={eventLogoDesktop} alt="" className="w-4/5 " />
              </div>
              <div className="flex tablet:pr-8 desktop-m:p-0 justify-end desktop-m:min-w-[40rem]">
                {invite ? (
                  <h1 className='tablet:text-4xl laptop:text-5xl desktop-m:text-5xl font-["FcMinimal-bold"] text-black-text  text-end  desktop-m:pr-12'>
                    เปิดรับสมััคร
                    <br />
                    วันที่ 7 มีนาคม - 21 เมษายน 2566
                  </h1>
                ) : (
                  <h1 className='tablet:text-3xl laptop:text-4xl desktop-m:text-5xl font-["FcMinimal-bold"] text-center  text-black-text '>
                    ปิดรับสมััคร
                  </h1>
                )}
              </div>
            </div>
          </div>

          <div
            className={`fixed w-full bottom-0  z-50  transform duration-200 bg-red-accent p-2 tablet:p-3 flex justify-center font-['FcMinimal-bold']  `}
          >
            <a
              href="https://techsauceteam.typeform.com/to/c6R29mHi?typeform-source=www.google.com"
              className="border-2 border-white-primary rounded-[45px] text-white-primary p-1 tablet:p-2 w-[18rem] text-center text-xl tablet:text-2xl"
            >
              สมัครเข้าร่วมโครงการ
            </a>
          </div>
        </section>
      )}
      <div className="desktop-m:flex desktop-m:flex-col desktop-m:items-center bg-white-secondary overflow-x-hidden">
        {/* about */}
        {openSection.about && (
          <section
            id="about"
            className="mt-8 font-['FcMinimal-normal'] text-black-text text-lg tablet:text-2xl  desktop-m:flex desktop-m:flex-col desktop-m:justify-center desktop-m:items-center desktop-m:max-w-[1280px]"
          >
            <div className="grid grid-cols-12 p-4 tablet:p-20 tablet:pr-0">
              <div className="col-span-12 tablet:col-span-6 flex flex-col justify-center">
                <h2 className="text-red-accent text-3xl tablet:text-4xl  font-['FcMinimal-bold']">
                  เกี่ยวกับโครงการ
                </h2>
                <p className="mt-4 whitespace-pre-line">
                  เนื่องจากการเปลี่ยนแปลงอย่างรวดเร็วและต่อเนื่องของพฤติกรรมลูกค้า
                  และเทคโนโลยี
                  ธุรกิจจึงต้องปรับตัวเพื่อให้ทันต่อการเปลี่ยนแปลงดังกล่าว
                  การขับเคลื่อนเรื่อง Digital Transformation
                  จะช่วยเพิ่มขีดความสามารถในการแข่งขันให้กับผู้ประกอบการ ธุรกิจ
                  รวมถึงประชาชนทั่วไป
                  <br /> <br /> ETDA ได้เล็งเห็นความสำคัญและได้ส่งเสริมให้เกิด
                  Ecosystem ที่เอื้อต่อการสนับสนุน
                  การทําธุรกรรมทางอิเล็กทรอนิกส์ โดยในช่วงที่ผ่านมา ETDA
                  ได้มีส่วนในการให้ความรู้
                  <br className="hidden desktop:block" />
                  ตลอดจนการส่งเสริมนวัตกรรมผ่านกิจกรรมต่างๆ
                  <br className="tablet:hidden" />{" "}
                  <br className="tablet:hidden" />
                  และเพื่อให้เกิดความต่อเนื่องของการดำเนินงานจึงได้จัดโครงการ{" "}
                  <span className="text-red-accent  font-['FcMinimal-bold']">
                    Hack for GROWTH
                  </span>{" "}
                  เพื่อส่งเสริมนวัตกรรมและพัฒนาทักษะสําหรับผู้ประกอบการ
                  ด้วยการหา Pain Point ตลอดจนความต้องการการสนับสนุน
                  ให้ตอบโจทย์ที่ตรงประเด็นสําหรับ MSMEs
                  เพื่อนําไปสู่แนวทางการทรานส์ฟอร์ม
                  และต่อยอดสู่การดําเนินงานในอนาคต
                </p>
              </div>
              <div className="col-span-12 tablet:col-start-8 tablet:col-span-5 flex justify-center tablet:justify-end">
                <img src={about} alt="" />
              </div>
            </div>
          </section>
        )}

        {openSection.winner && (
          <section
            id="winner"
            className="flex flex-row justify-center pb-10 tablet:pb-14 text-black-text font-[FcMinimal-normal]  desktop-m:flex desktop-m:flex-col desktop-m:justify-center desktop-m:items-center desktop-m:w-[1280px] "
          >
            <div className="flex flex-col  items-center w-full bg-gradient-to-t  from-blue-primary">
              <div className="mobile:w-[75%] tablet:w-[60%]  flex flex-col items-center ">
                <h2 className="text-red-accent text-3xl tablet:text-4xl text-center font-['FcMinimal-bold']">
                  ประกาศรายชื่อผู้ชนะการแข่งขัน
                </h2>
                <div className="grid mobile:grid-flow-row tablet:grid-cols-2  mobile:gap-5 tablet:gap-7 w-full mt-10 ">
                  <Winner />
                </div>
                <div className="flex flex-row justify-center items-center gap-4 mt-8 font-bold mobile:text-[18px] tablet:text-[22px]  laptop:text-[26px]  desktop-m:text-[32px] text-red-accent">
                  <a href="/">ดูข้อมูลเพิ่มเติม </a>
                  <img
                    src={redArrow}
                    alt="arrow"
                    className="w-[20px] h-[20px]"
                  />
                </div>
              </div>
            </div>
          </section>
        )}
        {/* activity */}
        {openSection.activity && (
          <section
            id="activity"
            className="flex flex-row justify-center  bg-gradient-radial-section-activity  pb-10 tablet:pb-14 text-white font-[FcMinimal-normal]  desktop-m:flex desktop-m:flex-col desktop-m:justify-center desktop-m:items-center desktop-m:max-w-[1280px]"
          >
            <div className="w-4/5 ">
              <div className="flex flex-col items-center w-full">
                <h2 className="text-red-accent text-3xl tablet:text-4xl text-center font-['FcMinimal-bold']">
                  ภาพบรรยากาศกิจกรรม
                </h2>
              </div>
              <div className="">
                <Activity />
              </div>
            </div>
          </section>
        )}
        {/* challenge */}
        {openSection.challenge && (
          <section
            id="challenge"
            className="mt-12 tablet:mt-8 font-['FcMinimal-normal'] text-black-text text-lg tablet:text-2xl  desktop-m:flex desktop-m:flex-col desktop-m:justify-center desktop-m:items-center desktop-m:w-[1280px]"
          >
            <div className="flex flex-col">
              <h2 className="text-red-accent text-3xl tablet:text-4xl text-center font-['FcMinimal-bold']">
                โจทย์ท้าทายจากทางโครงการฯ
              </h2>
              <Challenges />
            </div>
          </section>
        )}
        {/* criteria */}
        {openSection.criteria && (
          <section
            id="criteria"
            className="mt-12 tablet:mt-8  font-['FcMinimal-normal'] text-black-text text-lg tablet:text-2xl  desktop-m:flex desktop-m:flex-col desktop-m:justify-center desktop-m:items-center desktop-m:w-[1280px]"
          >
            <div className="flex flex-col tablet:h-[1048px] laptop:h-[856px] desktop-m:h-[756px] p-4 tablet:p-20 tablet:pt-4 bg-criteria">
              <h2 className="text-red-accent text-3xl tablet:text-4xl text-center font-['FcMinimal-bold']">
                คุณสมบัติผู้เข้าร่วม
              </h2>
              <div className="grid grid-cols-1 tablet:grid-cols-12  tablet:mt-12 mobile:p-4 tablet:p-0">
                <div className="col-span-5">
                  <img
                    src={lineCriteria}
                    alt=""
                    className="absolute z-30 left-[170px] tablet:-mt-8 mobile:hidden laptop:block laptop:h-[370px] laptop:w-[800px] min-[1152px]:w-[900px] desktop-m:-mt-8 desktop-m:h-[270px] desktop-m:w-[1000px] min-[1300px]:hidden"
                  />
                </div>
                <div className="tablet:col-start-8 laptop:col-start-7 tablet:col-span-5 ">
                  <ul className="list-disc">
                    <li className="text-red-accent">
                      อายุ 18 ปีขึ้นไป ไม่จำกัดเพศ อาชีพ ประสบการณ์
                      สัญชาติ/เชื้อชาติใดก็ได้
                    </li>
                    <li>
                      <span className="text-red-accent">
                        พักอาศัยอยู่ประเทศไทยในระหว่างโครงการ
                      </span>
                    </li>
                    <li>
                      สามารถเข้าร่วมกิจกรรมได้
                      <span className="text-red-accent">ตลอดโครงการ</span>
                    </li>
                    <li>
                      <span className="text-red-accent">
                        สมัครรูปแบบทีม 3-5 ท่าน
                      </span>{" "}
                      โดย 1 ท่านมีชื่อเพียงทีมเดียวเท่านั้น
                    </li>
                    <li className="text-red-accent">
                      หนึ่งในสมาชิกทีมมีพื้นฐานด้านการพัฒนา Software
                    </li>
                    <li className="text-red-accent">
                      มีสังกัดหน่วยงานที่ชัดเจน
                    </li>
                    <li>
                      โซลูชันที่สมัครเข้าร่วมโครงการ{" "}
                      <span className="text-red-accent">
                        ต้องเป็นโซลูชันใหม่, ต่อยอดจากโซลูชันเดิม
                        หรือเป็นผู้คิดค้นโซลูชันด้วยตนเอง
                      </span>
                    </li>
                    <li>
                      <span className="text-red-accent">
                        โซลูชันที่นําเสนอสอดคล้องกับโจทย์
                      </span>
                      ที่ประกาศในโครงการ
                    </li>
                    <li className="text-red-accent">
                      โซลูชันที่นําเสนอหรือองค์ประกอบ{" "}
                      <span>(ภาพ,เสียง, เนื้อหา)</span>{" "}
                      จะต้องเป็นผลงานที่ไม่ละเมิดลิขสิทธิ์และทรัพย์สินทางปัญญาของบุคคลอื่น
                    </li>
                    <li>
                      สามารถนําเสนอผลงานเป็น
                      <span className="text-red-accent">
                        ภาษาไทยหรือภาษาอังกฤษ
                      </span>
                      ได้
                    </li>
                    <li>
                      <span className="text-red-accent">
                        ยินดียอมรับผลการตัดสิน
                      </span>{" "}
                      รวมถึงหลักเกณฑ์ เงื่อนไข ในการตัดสินทุกประการ
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tablet:hidden flex justify-center">
                <img src={criteriaMobile} alt="criteria" />
              </div>
            </div>
          </section>
        )}
        {/* application-process */}
        {openSection.applicationProcess && (
          <section
            id="application-process"
            className="mt-8 font-['FcMinimal-normal'] text-black-text text-lg tablet:text-2xl  desktop-m:w-[1280px]"
          >
            <div className="hidden tablet:block p-8 px-20 bg-process h-[520px]">
              <h2 className="text-red-accent text-3xl tablet:text-4xl text-center tablet:text-start font-['FcMinimal-bold']">
                ขั้นตอนการสมัคร
              </h2>
              <div className="">
                <div className="flex flex-row items-center">
                  <h2 className="text-red-accent text-[90px] font-['FcMinimal-bold']">
                    1
                  </h2>
                  <p className="pl-8 pb-4">
                    ลงทะเบียนเข้าร่วมโครงการผ่านแบบฟอร์มการสมัครออนไลน์
                    <br />
                    ตั้งแต่วันที่ 7 มี.ค. - 21 เม.ย. 2566
                  </p>
                </div>
                <div className="flex flex-row items-center border-t-[6px] laptop:border-r-[6px]  -mt-4 laptop:w-[87%] pl-12 pt-8 border-red-accent">
                  <h2 className="text-red-accent text-[90px] font-['FcMinimal-bold']">
                    2
                  </h2>
                  <p className="pl-8 pb-4">
                    กรอกข้อมูลให้ครบถ้วน และตรวจสอบข้อมูลทั้งหมดให้เรียบร้อย
                  </p>
                </div>
                <div className="flex flex-row items-center border-t-[6px] laptop:border-r-[6px]  ml-[3.2rem] -mt-1  laptop:w-[74%] pl-12 pt-8 border-red-accent">
                  <h2 className="text-red-accent text-[90px] font-['FcMinimal-bold']">
                    3
                  </h2>
                  <p className="pl-8 pb-4">
                    รอผลการคัดเลือกจากคณะกรรมการเพื่อเข้าสู่รอบ 40 ทีม
                  </p>
                </div>
                <div className="flex flex-row items-center border-t-[6px] laptop:border-r-[6px]  ml-[6.2rem] -mt-1  laptop:w-[61%] pl-12 pt-8 border-red-accent">
                  <h2 className="text-red-accent text-[90px] font-['FcMinimal-bold']">
                    4
                  </h2>
                  <p className="pl-8 pb-4">
                    รอประกาศผล 40 ทีมที่ผ่านเข้าร่วมโครงการ
                  </p>
                </div>
                <div className="flex flex-row items-center border-t-[6px] laptop:border-r-[6px]  ml-[9.2rem] -mt-1 h-[5.3rem]  laptop:w-[48%] pl-12 pt-8 border-red-accent"></div>
              </div>
            </div>

            <div className="flex tablet:hidden flex-col  p-4 pr-0">
              <h2 className="text-red-accent text-3xl tablet:text-4xl text-start tablet:text-start font-['FcMinimal-bold']">
                ขั้นตอนการสมัคร
              </h2>
              <div className="flex flex-row items-center justify-center mt-8 w-4/5">
                <h2 className="text-red-accent text-[80px] font-['FcMinimal-bold']">
                  1
                </h2>
                <p className="pl-8 w-full">
                  ลงทะเบียนเข้าร่วมโครงการผ่านแบบฟอร์มการสมัครออนไลน์
                  <br />
                  ตั้งแต่วันที่ 7 มี.ค. - 21 เม.ย. 2566
                </p>
              </div>
              <div className="flex flex-row items-center justify-center pt-4 w-[95%] border-t-2 border-r-2 border-red-accent">
                <h2 className="text-red-accent text-[80px] font-['FcMinimal-bold']">
                  2
                </h2>
                <p className="pl-8 w-full">
                  กรอกข้อมูลให้ครบถ้วน
                  <br /> และตรวจสอบข้อมูลทั้งหมด ให้เรียบร้อย
                </p>
              </div>
              <div className="flex flex-row items-center justify-center pt-4 w-[95%] border-t-2 border-r-2 border-red-accent">
                <h2 className="text-red-accent text-[80px] font-['FcMinimal-bold']">
                  3
                </h2>
                <p className="pl-8 w-full">
                  รอผลการคัดเลือกจากคณะกรรมการ
                  <br />
                  เพื่อเข้าสู่รอบ 40 ทีม
                </p>
              </div>
              <div className="flex flex-row items-center justify-center pt-4 w-[95%] border-t-2 border-r-2 border-red-accent">
                <h2 className="text-red-accent text-[80px] font-['FcMinimal-bold']">
                  4
                </h2>
                <p className="pl-8 w-full">
                  รอประกาศผล 40
                  <br /> ทีมที่ผ่านเข้าร่วมโครงการ
                </p>
              </div>
              <div className=" h-28 w-[95%] border-t-2 border-r-2 border-red-accent z-30 flex justify-end items-end pl-4">
                <div className=" w-2 h-2 bg-red-accent rounded-[50%] mr-[-5px]"></div>
              </div>
              <div className="flex justify-end -mt-20">
                <img src={processMobile} alt="" className="max-w-[350px]" />
              </div>
            </div>
          </section>
        )}
        {/* benefit */}
        {openSection.benefit && (
          <section
            id="benefit"
            className="mt-8 font-['FcMinimal-normal'] text-lg tablet:text-2xl bg-[#f6f4f2]  desktop-m:flex desktop-m:flex-col desktop-m:justify-center desktop-m:items-center desktop-m:w-[1280px]"
          >
            <div className="mobile:hidden tablet:block bg-benefit-layer-1 h-[993px] desktop-m:w-[1280px] p-8 laptop:p-20 pt-12">
              <img
                src={trophy}
                alt="trophy"
                className="h-[90px] w-[90px] z-30 absolute"
              />
              <img
                src={lineBenefit}
                alt=""
                className="absolute w-[85%] desktop-m:w-[1100px] h-[560px] mt-12 ml-20 "
              />
              <div className=" flex flex-col justify-between h-[850px]">
                <div className="grid grid-cols-12">
                  <div className="col-start-2 w-full col-span-5 flex flex-row mt-12 justify-start ">
                    <h2 className="px-8 desktop-m:px-4 py-2 -mt-12 text-3xl laptop:text-4xl font-['FcMinimal-bold'] text-red-accent">
                      รางวัลสำหรับทีมผู้ชนะ
                    </h2>
                  </div>
                  <div className="col-start-6 col-span-7">
                    <div className="pl-4 min-[900px]:pl-0 laptop:pl-4  min-[1200px]:pl-0 w-[620px]">
                      <h1 className="text-red-accent text-2xl laptop:text-4xl font-['FcMinimal-bold']">
                        รางวัลชนะเลิศ
                      </h1>
                      <p className="text-lg laptop:text-2xl">
                        <b className="font-['FcMinimal-bold'] ">
                          เงินรางวัล 500,000 บาท
                        </b>{" "}
                        พร้อมโล่รางวัล ประกาศณียบัตร
                      </p>
                    </div>
                    <div className="pl-16 laptop:pl-20 w-[620px] mt-28 laptop:mt-24">
                      <h2 className="text-red-accent font-['FcMinimal-bold'] text-2xl laptop:text-4xl  mt-8">
                        รองชนะเลิศอันดับที่ 1
                      </h2>
                      <p className="text-lg laptop:text-2xl">
                        <b className="font-['FcMinimal-bold'] ">
                          เงินรางวัล 250,000 บาท
                        </b>{" "}
                        พร้อมโล่รางวัล และประกาศณียบัตร
                      </p>
                    </div>
                    <div className="pl-28 laptop:pl-36 desktop-m:pl-40 w-[720px] mt-24 laptop:mt-20">
                      <h2 className="text-red-accent font-['FcMinimal-bold']  text-2xl laptop:text-4xl  mt-8">
                        รองชนะเลิศอันดับที่ 2
                      </h2>
                      <p className="text-lg laptop:text-2xl">
                        <b className="font-['FcMinimal-bold'] ">
                          เงินรางวัล 100,000 บาท
                        </b>{" "}
                        พร้อมโล่รางวัล และประกาศณียบัตร
                      </p>
                    </div>
                    <div className="pl-40 min-[900px]:pl-[11rem] laptop:pl-52 min-[1100px]:pl-56 desktop-m:pl-60 w-[620px] mt-20 laptop:mt-16 ">
                      <h2 className="text-red-accent font-['FcMinimal-bold']  text-2xl laptop:text-4xl  mt-8">
                        รางวัลขวัญใจกรรมการ 2 รางวัล
                      </h2>
                      <p className="text-lg laptop:text-2xl">
                        <b className="font-['FcMinimal-bold'] ">
                          รางวัลละ 75,000 บาท พร้อมประกาศนียบัตร
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center  text-black-text ">
                  <h2 className="text-red-accent font-['FcMinimal-bold'] text-center text-2xl mt-8">
                    ทีมผู้ชนะทั้ง 3 ทีมจะได้โอกาสเข้าร่วมนำเสนอผลงาน (Pitching){" "}
                    <br />
                    บนเวที Tech showcase
                    <br /> ในงาน Techsauce Global Summit 2023
                  </h2>
                  <p className="text-center text-2xl">
                    <b>รางวัลสำหรับทีมที่เข้ารอบชิงชนะเลิศ 20 ทีม</b>
                    <br />
                    ได้รับประกาศณียบัตร <br />
                    ได้รับบัตรเข้าร่วมงาน Techsauce Global Summit 2023
                  </p>
                </div>
              </div>
            </div>
            <div className="flex tablet:hidden flex-col items-center justify-center p-4 text-base tablet:hiiden">
              <img src={trophy} alt="trophy" className="h-[90px] w-[90px]" />
              <h1 className="text-red-accent text-4xl font-['FcMinimal-bold'] mt-8">
                รางวัลชนะเลิศ
              </h1>
              <p className="text-center text-lg mt-6 ">
                <b className="font-['FcMinimal-bold'] text-xl">
                  เงินรางวัล 500,000 บาท
                </b>{" "}
                <br />
                พร้อมโล่รางวัล ประกาศณียบัตร
              </p>
              <h2 className="text-red-accent font-['FcMinimal-bold'] text-2xl mt-8">
                รองชนะเลิศอันดับที่ 1
              </h2>
              <p className="text-center">
                <b className="font-['FcMinimal-bold'] text-xl">
                  เงินรางวัล 250,000
                </b>
                <br /> พร้อมโล่รางวัล และประกาศณียบัตร
              </p>
              <h2 className="text-red-accent font-['FcMinimal-bold'] text-2xl mt-8">
                รองชนะเลิศอันดับที่ 2
              </h2>
              <p className="text-center">
                <b className="font-['FcMinimal-bold'] text-xl">
                  เงินรางวัล 100,000
                </b>
                <br /> พร้อมโล่รางวัล และประกาศณียบัตร
              </p>
              <h2 className="text-red-accent font-['FcMinimal-bold'] text-2xl mt-8">
                รางวัลขวัญใจกรรมการ 2 รางวัล
              </h2>
              <p className="text-center">
                <b className="font-['FcMinimal-bold'] text-xl">
                  รางวัลละ 75,000 บาท
                </b>
                <br /> พร้อมประกาศนียบัตร
              </p>
              <hr className="border border-red-accent mt-8 w-full min-[500px]:w-[300px]" />

              <h2 className="text-red-accent font-['FcMinimal-bold'] text-xl mt-8 text-center min-[500px]:w-[300px]">
                ทีมผู้ชนะทั้ง 3 ทีมจะได้โอกาสเข้าร่วมนำเสนอผลงาน (Pitching)
                บนเวที Tech showcase ในงาน Techsauce Global Summit 2023
              </h2>
              <b className="mt-8 font-['FcMinimal-bold'] text-xl">
                รางวัลสำหรับทีมที่เข้ารอบชิงชนะเลิศ 20 ทีม
              </b>

              <ul className="list-disc  px-8">
                <li>ได้รับประกาศณียบัตร</li>
                <li>ได้รับบัตรเข้าร่วมงาน Techsauce Global Summit 2023</li>
              </ul>

              <hr className="border border-red-accent mt-8 w-full min-[500px]:w-[300px]" />
            </div>
          </section>
        )}
        {/* calendar */}
        {openSection.calendar && (
          <section
            id="calendar"
            className="mt-8 font-['FcMinimal-normal'] text-black-text text-lg tablet:text-2xl  desktop-m:flex desktop-m:flex-col desktop-m:justify-center desktop-m:items-center desktop-m:max-w-[1280px]"
          >
            <h2 className="text-red-accent text-3xl tablet:text-4xl text-center font-['FcMinimal-bold']">
              ปฏิทินโครงการ
            </h2>
            <Calendar />
          </section>
        )}
      </div>
      {/* partners */}
      {openSection.partners && (
        <section
          id="partners"
          className="mt-8 font-['FcMinimal-normal'] text-black-text text-lg tablet:text-2xl"
        >
          <div className=" bg-white-primary flex justify-center items-center gap-8 p-8">
            <div>
              <img src={etda} alt="" />
            </div>
            <div>
              <img src={techsauce} alt="" />
            </div>
          </div>
        </section>
      )}
    </>
  );
}
