import React from "react";
import logo from "../../assets/images/partner/etda-logo.png";
import hamberger from "../../assets/images/mobile/hamberger-menu-mobile.png";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  createTheme,
  ThemeProvider,
} from "@mui/material";
interface Props {
  window?: () => Window;
}

const drawerWidth = 240;
const theme = createTheme({
  typography: {
    fontFamily: "FcMinimal-bold",
  },
});
export default function Navbar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const openInviteButton: boolean = false;
  const drawer = (
    <ThemeProvider theme={theme}>
      <Box
        onClick={handleDrawerToggle}
        sx={{
          textAlign: "center",
          background: "#F5F5F5",
          color: "white",
          height: "100%",
        }}
      >
        <div className="flex flex-row justify-center p-4 bg-white-secondary ">
          <div className="flex flex-row items-center scale-50 h-[22px] bg-white-primary">
            <img src={logo} alt="logo" />
          </div>
        </div>

        <List
          sx={{ fontFamily: "FcMinimal-bold", padding: 0, color: "#BA2B2C" }}
        >
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "center" }} href="#calendar">
              <ListItemText
                primaryTypographyProps={{ fontSize: "20px" }}
                primary={"ปฎิทินโครงการ"}
                sx={{ fontSize: "24px" }}
              />
            </ListItemButton>
          </ListItem>
          {/* <Divider
              sx={{ border: "1px solid #BA2B2C", marginX: "10px",opacity:"0.5" }}
            />
             <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }} href="#activity">
                <ListItemText
                  primaryTypographyProps={{ fontSize: "20px" }}
                  primary={"ภาพบรรยากาศกิจกรรม"}
                  sx={{ fontSize: "24px" }}
                />
              </ListItemButton>
            </ListItem>
            <Divider
              sx={{ border: "1px solid #BA2B2C", marginX: "10px",opacity:"0.5" }}
            />
             <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }} href="#winner">
                <ListItemText
                  primaryTypographyProps={{ fontSize: "20px" }}
                  primary={"ประกาศผู้ชนะเลิศ"}
                  sx={{ fontSize: "24px" }}
                />
              </ListItemButton>
            </ListItem>
            <Divider
              sx={{ border: "1px solid #BA2B2C", marginX: "10px",opacity:"0.5" }}
            />
   */}
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              href="https://techsauceteam.typeform.com/to/c6R29mHi?typeform-source=www.google.com"
            >
              <ListItemText
                primaryTypographyProps={{ fontSize: "20px" }}
                primary={"สมัครเข้าร่วมโครงการ"}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </ThemeProvider>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className="flex text-white font-['FcMonomal']">
      <AppBar
        sx={{
          border: "none",
          backgroundColor: "#F5F5F5",
          boxShadow: "none",
        }}
        component="nav"
        position="static"
      >
        <Toolbar
          sx={{ paddingLeft: "0 !important", minHeight: "50px !important" }}
        >
          <div className="flex flex-row justify-between w-full h-full font-['FcMinimal-bold'] p-0">
            <div className="flex flex-row items-center bg-white-secondary p-4 rounded-tr-[20px] rounded-br-[20px] mobile:w-[120px] mobile:h-50px tablet:w-[140px] tablet:h-[60px]">
              <img src={logo} alt="logo" />
            </div>
            <ul className="hidden tablet:flex list-none justify-around items-center font-bold text-xl space-x-4 text-red-accent">
              {/* open on 21 Mar 2023  */}
              {/* <li>
               <a href="#activity">ภาพบรรยากาศกิจกรรม</a>
             </li>
             <li>
               <a href="#winner">ประกาศผู้ชนะเลิศ</a>
             </li> */}
              <li>
                <a href="#calendar">ปฎิทินโครงการ</a>
              </li>
              <li>
                <a
                  href="https://techsauceteam.typeform.com/to/c6R29mHi?typeform-source=www.google.com"
                  className="flex items-center justify-center w-56 h-12   rounded-[40px]  font-[FcMinimal-bold] text-2xl bg-red-accent text-white-text font-bold "
                >
                  สมัครเข้าร่วมโครงการ
                </a>
              </li>
            </ul>
            <button className="tablet:hidden" onClick={handleDrawerToggle}>
              <img src={hamberger} alt="button"></img>
            </button>
          </div>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRadius: "0 35px 35px 0",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
}
